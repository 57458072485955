<template>
   <b-card
  class="mt-3 mb-3 border-0 qr-upload-card d-none d-md-block"
  no-body
>
      <div class="card-inner p-4">
        <b-row align-v="center" no-gutters>
          <b-col cols="12" md="7">
            <div class="content-wrapper">
              <div class="d-flex align-items-center mb-3">
                <div class="icon-wrapper mr-3">
                  <feather-icon
                    icon="SmartphoneIcon"
                    class="text-primary"
                    size="24"
                  />
                </div>
                <h5 class="mb-0 font-weight-bold">Continue on Phone</h5>
              </div>
              
              <p class="text-muted mb-3">
                Take high-quality product photos directly with your phone camera. 
                Scan the QR code to continue on your mobile device.
              </p>
  
              <div class="scan-instruction d-flex align-items-center">
                <div class="instruction-icon mr-2">
                  <feather-icon
                    icon="CameraIcon"
                    size="18"
                    class="text-primary"
                  />
                </div>
                <span class="text-primary font-weight-medium">Scan with your phone camera</span>
              </div>
            </div>
          </b-col>
  
          <b-col cols="12" md="5" class="mt-3 mt-md-0">
            <div class="qr-wrapper d-flex justify-content-md-end justify-content-center">
              <div class="qr-container">
                <div class="qr-code-box">
                  <img 
                    :src="qrCodeUrl"
                    alt="QR Code to open on mobile"
                    class="w-100 h-100"
                  />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
  } from 'bootstrap-vue'
  
  export default {
    name: 'QrUploadSection',
    
    components: {
      BCard,
      BRow,
      BCol,
    },
    
    data() {
      return {
        qrCodeUrl: ''
      }
    },
    
    created() {
      this.generateQrCode()
    },
    
    methods: {
      generateQrCode() {
        // Get the current page URL
        const currentUrl = window.location.href;
        this.qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(currentUrl)}`;
      }
    }
  }
  </script>
  
  <style scoped>
  .qr-upload-card {
    background: linear-gradient(145deg, #f0f7ff 0%, #f8f9ff 100%);
    border-radius: 16px !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  }
  
  .card-inner {
    position: relative;
    overflow: hidden;
  }
  
  .icon-wrapper {
    background-color: rgba(47, 97, 255, 0.1);
    border-radius: 12px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .scan-instruction {
    background-color: rgba(47, 97, 255, 0.08);
    padding: 8px 16px;
    border-radius: 8px;
    margin-top: 1rem;
  }
  
  .instruction-icon {
    background-color: white;
    border-radius: 6px;
    padding: 6px;
    box-shadow: 0 2px 6px rgba(47, 97, 255, 0.1);
  }
  
  .qr-container {
    background: white;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .qr-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.12);
  }
  
  .qr-code-box {
    width: 150px;
    height: 150px;
    padding: 8px;
    background: white;
    border-radius: 12px;
    border: 2px solid #f0f0f0;
  }
  
  @media (max-width: 768px) {
    .qr-wrapper {
      margin-top: 1.5rem;
    }
    
    .content-wrapper {
      text-align: center;
    }
    
    .icon-wrapper {
      margin: 0 auto;
    }
    
    .d-flex {
      justify-content: center;
    }
  }
  </style>
<template>
  <div class="requirements-alert">
    <div class="requirements-content">
      <div class="header-row">
        <span class="camera-icon">📸</span>
        <h6 class="requirements-title">Mandatory Photo Requirements (For better results):</h6>
      </div>
      
      <div class="requirements-list">
        <p class="requirement-item">
          <span class="accent">Upload at least 5 high-quality images</span> with your product or item centered. Avoid including other objects in the images.
        </p>
        
        <p class="requirement-item">
          <span class="accent">Phone camera photos</span> are highly recommended for the best results. Scan QR code to open this page on your phone and upload directly.
        </p>
        
        <p class="requirement-item mb-0">
          Provide photos from <span class="accent">different angles</span> (front, back, top and all sides) to ensure better outputs from all perspectives.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhotoRequirements'
}
</script>

<style scoped>
.requirements-alert {
  background-color: #f8faff;
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1rem 0;
}

.requirements-content {
  color: #2c3e50;
}

.header-row {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}

.camera-icon {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}

.requirements-title {
  margin: 0;
  font-size: 0.95rem;
  font-weight: 600;
}

.requirements-list {
  padding-left: 0.25rem;
}

.requirement-item {
  margin-bottom: 1rem;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #4a5568;
}

.accent {
  font-weight: 600;
  color: #2c3e50;
}

@media (max-width: 768px) {
  .requirements-alert {
    padding: 1.25rem;
  }
  
  .requirements-title {
    font-size: 0.9rem;
  }
  
  .requirement-item {
    font-size: 0.9rem;
  }
}
</style>
<template>
    <b-modal
      id="modal-product-training"
      title="Product Training"
      size="lg"
      hide-footer
      @hidden="resetForm"
    >
      <div class="product-training px-2">
        <!-- Progress Tracker -->
        <div v-if="progressVisible" class="progress-tracker mb-4">
    <!-- Training In Progress -->
    <div v-if="!trainingCompleted">
      <h3>Product Training In Progress</h3>
      <p>This process will take around 40-60 minutes based on the product images uploaded.</p>
      <p>Please keep this window open until the training process is complete.</p>
      <p>{{ progressMessage }}</p>
      <b-progress 
        :value="progressValue" 
        :max="100" 
        class="mb-3"
        :variant="progressValue >= 100 ? 'success' : 'primary'"
        animated
      ></b-progress>
    </div>

    <!-- Training Completed -->
    <div v-else class="text-center">
      <h3 class="text-success">Training Completed!</h3>
      <p>Your AI agent is ready to create images.</p>
      <b-button
        variant="primary"
        class="mt-3"
        @click="$router.push(`/agent/${agentId}`)"
      >
        <feather-icon icon="ImageIcon" size="16" class="mr-1" />
        Create Images
      </b-button>
    </div>
  </div>

    <!-- Training Error -->
    <div v-else-if="trainingError" class="text-center">
      <h3 class="text-danger">Training Error</h3>
      <p>{{ trainingError }}</p>
      <b-button
        variant="outline-primary"
        class="mt-3"
        @click="resetForm"
      >
        <feather-icon icon="RefreshCwIcon" size="16" class="mr-1" />
        Try Again
      </b-button>
    </div>
  </div>
  
        <div v-if="!progressVisible">
          <!-- <h5>Training Details:</h5> -->
          <b-form @submit.prevent="startTraining">
            <b-form-group label="Creative Agent Name:" label-for="agentName">
              <b-form-input
                id="agentName"
                v-model="trainingData.agentName"
                required
                placeholder="Enter agent name"
                :disabled="isTraining"
              />
            </b-form-group>
  
            <b-form-group label="Product Type:" label-for="productType">
            <b-form-select
            id="productType"
            v-model="trainingData.productType"
            :options="productTypeOptions"
            required
            />
            </b-form-group>
            <b-form-group label="Description:" label-for="description">
            <textarea
            id="description"
            v-model="cleanedDescription"
            class="form-control"
            rows="2"
            placeholder=""
            :disabled="isTraining"
            ></textarea>
            </b-form-group>
            <b-form-group label="Product Name (Unique product name with letters only):" label-for="productName">
              <b-form-input
                id="productName"
                v-model="trainingData.productName"
                required
                :disabled="flase"
              />
            </b-form-group>

            <photo-requirements />
            <qr-upload-section />

            <h5 class="mt-2">Product Images:</h5>
            <div class="image-grid mt-2 mb-4">
              <div v-for="image in allImages" :key="image.id" class="image-item">
                <b-form-checkbox v-model="image.selected" :disabled="isTraining">
                  <img :src="image.url" :alt="image.alt || 'Product image'" class="preview-image" />
                </b-form-checkbox>
              </div>
            </div>
  
            <div class="upload-section mb-2">
              <h6>Add More Images:</h6>
              <b-form-file
                v-model="newImages"
                multiple
                accept="image/jpeg, image/webp, image/png, image/heic, image/heif"
                placeholder="Choose images or drop them here..."
                drop-placeholder="Drop images here..."
                :disabled="isTraining"
                @input="handleNewImages"
              />

            </div>
  
  
            <div class="d-flex justify-content-between">
              <b-button 
                variant="secondary" 
                @click="$bvModal.hide('modal-product-training')"
                :disabled="isTraining"
              >
                Cancel
              </b-button>
              <b-button
                variant="primary"
                type="submit"
                :disabled="!isValid || isTraining"
              >
                <span v-if="!isTraining">Start Training</span>
                <span v-else>Training in Progress...</span>
              </b-button>
            </div>
          </b-form>
        </div>
    </b-modal>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import loadImage from 'blueimp-load-image';
  import heic2any from 'heic2any';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import axios from 'axios';
  import QrUploadSection from '@/views/Website/products/QrUploadSection.vue'
  import PhotoRequirements from '@/views/Website/products/photorequirements.vue' 
  import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BButton,
  BProgress,
  BFormSelect,
  BFormTextarea,
  BAlert
  } from 'bootstrap-vue';
  
  export default {
    name: 'ProductTrainingModal',
    components: {
    BAlert,
    BFormSelect,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BButton,
    BProgress,
    BFormTextarea,
    PhotoRequirements,
    QrUploadSection,
  },
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        trainingData: {
      agentName: '',
      productType: '', 
      productName: '',
      productId: '',
      description: ''
    },
        agentId: '',
        agentType: 'product',
        allImages: [],
        newImages: null,
        isTraining: false,
        trainingCompleted: false,
        progressVisible: false,
        progressMessage: '',
        progressValue: 0,
        progressMax: 100,
        sseSource: null,
        datasetId: '',
        trainingId: '',
        triggerWord: '',
        description: '',
        productTypeOptions: [
      { value: null, text: 'Please select', disabled: true },
      { value: 'fashion', text: 'Fashion & Accessories' },
      { value: 'furniture', text: 'Furniture & Home Decor' },
      { value: 'food', text: 'Food & Beverages' },
      { value: 'influencer', text: 'Influencer' },
      { value: 'health', text: 'Health & Wellness' },
      { value: 'technology', text: 'Technology & Gadgets' },
      { value: 'toys', text: 'Toys & Baby Products' },
      { value: 'automotive', text: 'Automotive & Accessories' },
      { value: 'books', text: 'Books & Stationery' },
      { value: 'sports', text: 'Sports & Outdoors' },
      { value: 'pets', text: 'Pets & Pet Care' },
      { value: 'beauty', text: 'Beauty & Personal Care' },
      { value: 'appliances', text: 'Home Appliances' },
      { value: 'product', text: 'Other' }
    ],
      };
    },

    async created() {
    // Check for existing training as soon as component is created
    await this.checkAndShowExistingTraining();
  },
  async mounted() {
    await this.checkAndShowExistingTraining();
  },

  watch: {
    // This will run whenever modal becomes visible
    '$attrs.visible': {
      immediate: true,
      async handler(isVisible) {
        if (isVisible) {
          await this.checkAndShowExistingTraining();
        }
      }
    }
  },

    computed: {
      ...mapState('app', ['userData']),
      isValid() {
    return this.trainingData.agentName && 
           this.getSelectedImagesCount() >= 5 &&
           this.trainingData.productType && // Add this check
           this.trainingData.productType !== null; // Ensure it's not null
  },
      loraNameSanitized() {
    const sanitizedName = this.trainingData.agentName.replace(/\s+/g, '').toLowerCase();
    const timestamp = Date.now();
    return `${sanitizedName}-${timestamp}`;
  },
  cleanedDescription: {
    get() {
      return this.cleanText(this.trainingData.description);
    },
    set(value) {
      this.trainingData.description = this.cleanText(value);
    }
  },
  cleanedProductName: {
    get() {
      return this.cleanText(this.trainingData.productName);
    },
    set(value) {
      this.trainingData.productName = this.cleanText(value);
    }
  }
    },
    watch: {
      product: {
        immediate: true,
        handler(newProduct) {
          if (newProduct) {
            this.initializeTrainingData();
          }
        }
      }
    },
    methods: {


      async checkAndShowExistingTraining() {
  const existingTrainingId = localStorage.getItem('trainingId');
  
  if (existingTrainingId) {
    try {
      // Show progress bar immediately
      this.progressVisible = true;
      this.isTraining = true;
      this.progressMessage = 'Checking training status...';
      
      // First check current status via direct API call
      const response = await fetch(`https://train.briks.ai/progress/${existingTrainingId}`);
      const data = await response.json();
      
      if (data.type === 'status' && data.status === 'completed') {
        // Training is already completed
        this.agentId = `agent-${existingTrainingId}`;
        this.progressValue = 100;
        this.handleTrainingCompletion();
        return;
      }
      
      // If training is still in progress, update progress info from current data
      if (data.type === 'progress') {
        this.trainingId = existingTrainingId;
        this.progressValue = data.percentComplete;
        this.progressMessage = `Training Progress: ${data.percentComplete}% (Step ${data.currentStep}/${data.totalSteps})`;
        
        if (data.loraName) {
          this.loraName = data.loraName;
          this.agentId = data.loraName;
        } else {
          this.agentId = `agent-${existingTrainingId}`;
        }
      }
      
      // Start SSE tracking
      this.trackTrainingProgress();

    } catch (error) {
      console.error('Error checking training status:', error);
      localStorage.removeItem('trainingId');
      this.progressVisible = false;
      this.isTraining = false;
      this.showToast('Error checking training status', 'error');
    }
  }
},

        cleanText(text) {
    if (!text) return '';
    
    // Remove HTML tags
    let cleaned = text.replace(/<[^>]*>/g, '');
    
    // Remove special characters but keep basic punctuation
    cleaned = cleaned.replace(/[^\w\s.,!?-]/g, '');
    
    // Remove extra whitespace
    cleaned = cleaned.replace(/\s+/g, ' ').trim();
    
    return cleaned;
  },

      initializeTrainingData() {
        this.trainingData = {
          agentName: `agent-${this.product.title}`,
          productType: this.product.productType || 'null',
          productName: this.product.title,
          productId: this.product.id,
          description: this.product.description || '',
        };   

        this.triggerWord = this.generateTriggerWord();

        this.allImages = this.product.images.map(img => ({
          ...img,
          selected: true
        }));
      },
  
      getSelectedImagesCount() {
        return this.allImages.filter(img => img.selected).length;
      },
  
      async handleNewImages(files) {
        if (!files) return;
        
        for (const file of files) {
          try {
            const processedImage = await this.processImage(file);
            this.allImages.push({
              id: Date.now() + Math.random(),
              url: processedImage.dataUrl,
              file: processedImage.file,
              selected: true
            });
          } catch (error) {
            console.error('Error processing image:', error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error processing image ${file.name}`,
                variant: 'danger'
              }
            });
          }
        }
        
        // Reset file input
        this.newImages = null;
      },
      cleanShopifyUrl(url) {
    try {
      // Parse the URL
      const urlObj = new URL(url);
      
      // Remove version and other query parameters
      urlObj.search = '';
      
      return urlObj.toString();
    } catch (error) {
      console.error('Error cleaning URL:', error);
      return url; // Return original URL if parsing fails
    }
  },
      async processImage(file) {
  try {
    let processedFile = file;

    // Convert HEIC/HEIF to JPEG if necessary
    if (
      file.type === 'image/heic' ||
      file.type === 'image/heif' ||
      file.name.toLowerCase().endsWith('.heic') ||
      file.name.toLowerCase().endsWith('.heif')
    ) {
      const conversionResult = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.8,
      });

      processedFile = Array.isArray(conversionResult) ? conversionResult[0] : conversionResult;
      processedFile = new File([processedFile], file.name.replace(/\.[^/.]+$/, '') + '.jpg', {
        type: 'image/jpeg',
        lastModified: file.lastModified
      });
    }

    // Create a promise to handle the image processing
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = (e) => {
        loadImage(
          e.target.result,
          (canvas) => {
            if (canvas.type === 'error') {
              reject(new Error('Failed to process image'));
              return;
            }

            const dataUrl = canvas.toDataURL(processedFile.type);
            resolve({
              dataUrl,
              file: processedFile
            });
          },
          {
            orientation: true,
            canvas: true,
            maxWidth: 1000,
            maxHeight: 1000,
          }
        );
      };

      reader.onerror = () => reject(new Error('Failed to read file'));
      reader.readAsDataURL(processedFile);
    });
  } catch (error) {
    throw new Error(`Image processing failed: ${error.message}`);
  }
},
    // Add image URL
    addImageUrl() {
      const urlPattern = /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|bmp|svg|webp))(?:\?.*)?$/i;
      if (this.newImageUrl.trim() !== '') {
        if (urlPattern.test(this.newImageUrl.trim())) {
          const imageItem = {
            id: Date.now() + Math.random(),
            dataUrl: this.newImageUrl.trim(),
            caption: '',
          };
          // Limit to 25 images
          if (this.allImages.length >= 25) {
            alert('You can only upload up to 25 images.');
            return;
          }
          this.allImages.push(imageItem);
          this.newImageUrl = '';
        } else {
          alert('Please enter a valid image URL (e.g., https://example.com/image.jpg)');
        }
      }
    },
    deleteImage(index) {
      this.allImages.splice(index, 1);
    },
  
    generateTriggerWord() {
  try {
    // Define prefix map
    const prefixMap = {
      fashion: 'fash',
      beauty: 'beauty',
      food: 'food',
      influencer: 'inf',
      default: 'briks'
    };

    // Helper function to sanitize and shorten
    const sanitizeAndShorten = (str, length = 3) => {
      if (!str) return 'xxx';
      return str
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '') // Remove special characters
        .substring(0, length); // Take first few chars
    };

    // Extract and process components
    const userName = sanitizeAndShorten(this.userData?.userName);
    const productName = sanitizeAndShorten(this.trainingData?.productName);
    const productType = this.trainingData?.productType?.toLowerCase() || 'default';

    // Get prefix from the map (fallback to 'default')
    const prefix = prefixMap[productType] || prefixMap.default;

    // Generate a 3-digit random number
    const uniqueId = Math.floor(100 + Math.random() * 900); // Random number between 100-999

    // Construct trigger word
    const triggerWord = `${prefix}-${userName}-${productName}-${uniqueId}`;

    console.log('Generated trigger word:', {
      prefix,
      userName,
      productName,
      uniqueId,
      final: triggerWord
    });

    return triggerWord;
  } catch (error) {
    console.error('Error generating trigger word:', error);
    
    // Fallback with timestamp for uniqueness
    const fallbackId = `${Date.now().toString(36)}-${Math.random().toString(36).substring(2, 6)}`;
    return `briks-${fallbackId}`;
  }
},

  
      async startTraining() {

          // First check if there's any existing training
      const existingTrainingId = localStorage.getItem('trainingId');
      if (existingTrainingId) {
        await this.checkAndShowExistingTraining();
        return; // Exit if there's an existing training
      }

      if (!this.validateInputs()) return;
    try {
  
      this.isTraining = true;
      this.progressVisible = true;
      this.triggerWord = this.generateTriggerWord();

      // Create dataset
      const datasetResponse = await this.createDataset();
      this.datasetId = datasetResponse.data.datasetId;
      this.showToast('Dataset created successfully', 'success');

      // Start training
      const trainingResponse = await this.startTrainingProcess();
      this.trainingId = trainingResponse.data.trainingId;
      localStorage.setItem('trainingId', this.trainingId);
      
      // Start tracking progress
      this.trackTrainingProgress();
      
    } catch (error) {
      console.error('Training error:', error);
      this.showToast(error.message || 'Training failed', 'error');
      this.isTraining = false;
    }
  },

  validateInputs() {
    const selectedImagesCount = this.getSelectedImagesCount();
    if (selectedImagesCount < 5) {
      this.showToast('Please select at least 5 images', 'error');
      return false;
    }
    if (!this.trainingData.agentName) {
      this.showToast('Please enter an agent name', 'error');
      return false;
    }
    if (!this.trainingData.productType) {
    this.showToast('Please select a product type', 'error');
    return false;
    }
    return true;
  },
  
    // Start the training process
    async startTraining() {
      if (!this.validateInputs()) {
        return;
      }
      try {
        this.isLoading = true; // Start loader
        this.isTraining = true; // Disable start button and indicate training is in progress
        this.trainingCompleted = false; // Reset training completed status

        // Step 1: Dataset creation
        const datasetResponse = await this.createDataset();
        this.datasetId = datasetResponse.data.datasetId;
        this.showToast('Images uploaded and now starting the training process.');

        // Step 2: Start training
        const trainingResponse = await this.startTrainingProcess();
        this.trainingId = trainingResponse.data.trainingId;
        // Store trainingId in localStorage
        localStorage.setItem('trainingId', this.trainingId);
        this.showToast('Training process started! Please be patient as it will take some time to complete.');

        // Track training progress
        this.trackTrainingProgress();
      } catch (error) {
        console.error('Error during training process:', error);
        this.showToast('An error occurred during training. Please try again.', 'error');
        this.isLoading = false; // Stop loader on error
        this.isTraining = false; // Re-enable start button
      }
    },
    // Validate inputs before starting the training
    validateInputs() {
      if (this.allImages.length < 5) {
        this.showToast('Please upload at least 5 images.', 'error');
        return false;
      }
      if (!this.agentType) {
        this.showToast('Please select your Agent Type.', 'error');
        return false;
      }
      // if (!this.purpose) {
      //   this.showToast('Please tell about product or influencer type for better results.', 'error');
      //   return false;
      // }
      return true;
    },
    // Creates a dataset by sending images and URLs to the server.
    async createDataset() {
    try {
      // Get selected images only
      const selectedImages = this.allImages.filter(img => img.selected);
      
      if (selectedImages.length === 0) {
        throw new Error('Please select at least one image');
      }
      if (selectedImages.length > 25) {
        throw new Error('Maximum 25 images allowed');
      }

      this.triggerWord = this.generateTriggerWord();
      console.log('Using trigger word:', this.triggerWord); 

      const formData = new FormData();

      // Process images and URLs separately
      const imageURLs = [];
      const processedImages = [];

      // Modified loop to clean Shopify URLs
      for (const imageItem of selectedImages) {
        if (imageItem.file) {
          formData.append('images', imageItem.file);
          processedImages.push({
            type: 'file',
            name: imageItem.file.name
          });
        } else if (imageItem.url) {
          const cleanedUrl = this.cleanShopifyUrl(imageItem.url);
          imageURLs.push(cleanedUrl);
          processedImages.push({
            type: 'url',
            original: imageItem.url,
            cleaned: cleanedUrl
          });
        }
      }

      // Log processed images for debugging
      console.log('Processed images:', {
        totalCount: processedImages.length,
        files: processedImages.filter(i => i.type === 'file').length,
        urls: processedImages.filter(i => i.type === 'url').length,
        details: processedImages
      });

      // Add imageURLs if any exist
      if (imageURLs.length > 0) {
        formData.append('imageURLs', JSON.stringify(imageURLs));
      }

      // Generate captions array with trigger word
      const captions = selectedImages.map(() => this.triggerWord);
      formData.append('captions', JSON.stringify(captions));

      // Add required fields
      formData.append('conceptSentence', this.triggerWord);
      formData.append('agentType', 'product');

      // Optional fields
      if (this.trainingData.productType) {
        formData.append('productType', this.trainingData.productType);
      }

      // Log formData for debugging
      for (let [key, value] of formData.entries()) {
        if (value instanceof File) {
          console.log(`${key}:`, `File: ${value.name}`);
        } else {
          console.log(`${key}:`, value);
        }
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      };

      // Call the API
      const response = await axios.post('https://train.briks.ai/create-dataset', formData, config);
      console.log('Dataset creation response:', response.data);
      return response;
    } catch (error) {
      console.error('Dataset creation error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      this.showToast(error.response?.data?.error || error.message || 'Failed to create dataset', 'error');
      throw error;
    }
  },
    // Start training process by calling the API
async startTrainingProcess() {
    if (!this.triggerWord) {
    this.triggerWord = this.generateTriggerWord();
  }

  const payload = {
    agentName: this.cleanText(this.trainingData.agentName),
    loraName: this.loraNameSanitized,
    conceptSentence: this.triggerWord,
    steps: 1500, 
    dataset: this.datasetId,
    userId: this.userData.userId,
    description: this.cleanText(this.trainingData.description),
    agentType: 'product',
    productType: this.trainingData.productType,
    productName: this.cleanText(this.trainingData.productName),
    userName: this.userData.userName,
    email: this.userData.email,
    productId: this.trainingData.productId
  };

  console.log('Training payload:', payload); 

  try {
    const response = await axios.post('https://train.briks.ai/start-training', payload);
    return response;
  } catch (error) {
    if (error.response?.data?.error === 'Trigger word already exists') {
      this.triggerWord = this.generateTriggerWord();
      this.showToast('Trigger word conflict detected. Generated a new trigger word.', 'warning');
      return await this.startTrainingProcess();
    }
    throw error;
  }
},
    // Track training progress via SSE
    trackTrainingProgress() {
  if (!this.trainingId) {
    console.error('No training ID available for progress tracking');
    return;
  }

  if (this.sseSource) {
    this.sseSource.close();
  }

  this.sseSource = new EventSource(`https://train.briks.ai/progress/${this.trainingId}`);
  this.progressVisible = true;

  this.sseSource.onmessage = async (event) => {
    try {
      const data = JSON.parse(event.data);
      console.log('Received SSE data:', data);

      if (data.type === 'progress') {
        // Update progress
        this.progressValue = data.percentComplete;
        this.progressMessage = `Training Progress: ${data.percentComplete}%`;
        
        // Store loraName for later use
        if (data.loraName) {
          this.loraName = data.loraName;
        }
      } else if (data.type === 'status' && data.status === 'completed') {
        // Handle status completion response
        this.progressValue = data.progress || 100;
        this.handleTrainingCompletion();
      } else if (data.type === 'end' && data.message === 'Training completed') {
        // Handle end message completion
        this.handleTrainingCompletion();
      }
    } catch (error) {
      console.error('Error processing SSE message:', error);
      this.handleTrainingError('Error processing training update');
    }
  };

  this.sseSource.onerror = (error) => {
    console.error('SSE Error:', error);
    if (!this.trainingCompleted) {
      setTimeout(() => {
        console.log('Attempting to reconnect SSE...');
        this.trackTrainingProgress();
      }, 45000);
    }
  };
},

handleTrainingCompletion() {
  // Set completion states
  this.progressMessage = 'Training completed!';
  this.progressValue = 100;
  this.trainingCompleted = true;
  this.isTraining = false;

  this.agentId = `agent-${this.trainingId}`;

  // Cleanup
  if (this.sseSource) {
    this.sseSource.close();
    this.sseSource = null;
  }
  localStorage.removeItem('trainingId');

  // Show success toast
  this.showToast('Training completed successfully!', 'success');
},

handleTrainingError(message) {
  this.progressMessage = 'Training failed';
  this.trainingError = message;
  this.isTraining = false;
  
  if (this.sseSource) {
    this.sseSource.close();
    this.sseSource = null;
  }
  localStorage.removeItem('trainingId');
  
  this.showToast(message, 'error');
},

  handleSSEError() {
    setTimeout(() => {
      console.log('Attempting to reconnect SSE...');
      this.trackTrainingProgress();
    }, 25000);
  },

  cleanupTrainingSession() {
    localStorage.removeItem('trainingId');
    if (this.sseSource) {
      this.sseSource.close();
      this.sseSource = null;
    }
  },

  
    // Redirect to Agent Page
    goToAgentPage() {
      window.location.href = `/agent/${this.agentId}`;
    },
    // Show login modal
    showLoginModal() {
      return new Promise((resolve) => {
        this.$bvModal.show('modal-login');
        const handleLoginSuccess = () => {
          resolve(true);
          cleanup();
        };
        const handleLoginFailure = () => {
          resolve(false);
          cleanup();
        };
        const cleanup = () => {
          this.$root.$off('login-success', handleLoginSuccess);
          this.$root.$off('login-failure', handleLoginFailure);
        };
        this.$root.$on('login-success', handleLoginSuccess);
        this.$root.$on('login-failure', handleLoginFailure);
      });
    },
    showToast(message, type = 'success') {
  let icon;
  switch(type) {
    case 'success':
      icon = 'CheckIcon';
      break;
    case 'error':
      icon = 'AlertTriangleIcon';
      break;
    case 'warning':
      icon = 'AlertCircleIcon';
      break;
    default:
      icon = 'InfoIcon';
  }
  
  this.$toast({
    component: ToastificationContent,
    props: {
      title: message,
      icon,
      variant: type
    }
  });
},
  
      resetForm() {
        this.trainingData = {
          agentName: '',
          productType: '',
          productName: '',
          productId: '',
          description: '' 
        };
        this.allImages = [];
        this.newImages = null;
        this.isTraining = false;
        this.trainingCompleted = false;
        this.progressVisible = false;
        this.progressValue = 0;
        this.datasetId = '';
        this.trainingId = '';
        this.triggerWord = '';
        
        if (this.sseSource) {
          this.sseSource.close();
          this.sseSource = null;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
  }
  
  .image-item {
    position: relative;
  }
  
  .preview-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .progress-tracker {
    text-align: center;
  }
  </style>
<template>
  <div>
    <div class='main-loader' v-if='isLoading'></div>
    <div class="product-page">
      <b-container>
        <!-- Page Header -->
        <div class="d-flex justify-content-between align-items-center mb-1">  

          <div>
  <b-button 
    variant="primary" 
    @click="createNewProduct" 
     class="upload-btn"
  >
    <feather-icon icon="UploadCloudIcon" size="15" class="mb-0" />
    Upload Images
  </b-button>
</div>

  <!-- Show when store is connected -->
  <div v-if="storeDetails" class="d-flex align-items-center">
    <!-- <p class="mb-0 mr-2">Store: {{ storeDetails.shopifyStoreName }}</p> -->
    <b-button
      variant="secondary"
      size="sm"
      @click="syncProducts"
      :disabled="isSyncing"
      class="d-flex mr-0 align-items-center compact-btn"
    >
      <feather-icon 
        icon="RefreshCwIcon" 
        size="14" 
        :class=" { 'fa-spin': isSyncing }"
        class="mr-1" 
      />
      {{ isSyncing ? 'Syncing...' : 'Sync' }}
    </b-button>
  </div>

  <!-- Show when no store is connected -->
  <div v-else>
    <!-- <b-button
      variant="primary"
      @click="connectShopify"
      class="mr-0 compact-btn"
    >
  
      Connect Shopify
      <img 
        src="https://www.svgrepo.com/show/303503/shopify-logo.svg" 
        alt="Shopify" 
        width="15" 
        class="mr-0 mb-0" 
      />
    </b-button> -->
    
  </div>
  
</div>


<!-- Show this when no store connected -->
<div v-if="products.length === 0 && !isLoading" class="text-center mt-5">
  <!-- Image Section -->
  <img 
    src="@/assets/images/upload.svg" 
    alt="BRIKS" 
    width="240px" 
    height="auto" 
    class="mb-2 mt-n2" 
  />

  <!-- Heading -->
  <h3 class="mt-2">
    Turn your phone camera shots into stunning, photorealistic photoshoot images in just 3 simple steps!
  </h3>

  <!-- Instruction List -->
  <div class="mt-2">
    <ul class="instruction-list text-start mx-auto" style="max-width: 800px;">
      <li>1. Upload your images</li>
      <li>2. Start Image Processing (processing times may vary based on the number of images)</li>
      <li>3. Create unlimited AI images using simple text prompts</li>
    </ul>
  </div>

  <!-- Upload Button -->
  <b-button 
    variant="primary" 
    @click="createNewProduct" 
    class="upload-btn mt-0"
  >
    <feather-icon icon="UploadCloudIcon" size="15" class="mb-0" />
    Upload Images
  </b-button>
</div>


        <!-- Products Grid -->
        <div v-if="products.length">
          <b-row>
             <b-col 
    v-for="product in products" 
    :key="product.id" 
    cols="12" 
    sm="6" 
    md="4" 
    lg="3" 
    class="mb-3"
  >
              <b-card no-body class="agent-card h-100">
                <!-- Product Images Carousel -->
                <b-carousel
                  v-if="product.images && product.images.length"
                  :id="'carousel-' + product.id"
                  controls
                  indicators
                  class="agent-list-img-object-fit"
                  background="#ababab"
                >
                  <b-carousel-slide
                    v-for="image in product.images"
                    :key="image.id"
                    :img-src="image.url"
                    class="agent-list-img-object-fit"
                  />
                </b-carousel>

                <!-- Top Right Icons -->
                <!-- <div class="top-right-icons">
                  <div class="love-icon top-right-icon">
                    <feather-icon 
                      icon="HeartIcon"
                      :class="['feather-icon', product.isLiked ? 'filled-heart' : 'empty-heart']"
                      size="16"
                    />
                  </div>
                  <div class="share-icon top-right-icon">
                    <feather-icon
                      icon="ShareIcon"
                      class="feather-icon"
                      size="16"
                    />
                  </div>
                </div> -->

                <b-card-body class="b-card-body">
                  <div>
                    <div class="d-flex justify-content-between align-items-center">
                    <h3 class="agent-name mb-0">{{ product.title }}</h3>
                    <div class="agent-rating d-flex align-items-center" v-if="product.imageCount">
  <feather-icon icon="ImageIcon" size="15" class="mr-1" />
  <span>{{ product.imageCount }}</span>
</div>
                   </div>
                  </div>

                  <!-- Training Status and Button -->
                  <div class="button-container">
                    <b-button
        v-if="!product.trainingStatus && !isTraining(product.id)"
        variant="secondary"
        block
        class="action-btn"
        @click="startTraining(product)"
    >
        <feather-icon icon="ZapIcon" size="16" class="mb-0" />
         Start Processing
    </b-button>
    
    <b-button
        v-else-if="isTraining(product.id)"
        variant="secondary"
        block
        class="cart-btn no-border-radius"
        disabled
    >
        <feather-icon icon="LoaderIcon" size="16" class="mb-0" />
        Processing...
    </b-button>
    
    <b-button
    v-else-if="product.trainingStatus && product.agentId"
    variant="primary"
    block
    :class="['action-btn', product.trainingStatus ? 'completed-training' : '']"
    @click="viewAgent(product.agentId)"
>
    <feather-icon icon="PlayIcon" size="16" class="mb-0" />
    Create Images
</b-button>
</div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

          <!-- Pagination -->
          <b-pagination
            v-if="totalPages > 1"
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="limit"
            :align="center"
            class="mt-4"
            @change="fetchProducts"
          />
        </div>

        <!-- No Products Message -->
        <!-- <div v-else-if="!isLoading" class="text-center mt-5">
          <h3>No products found</h3>
          <p>Please sync your Shopify store to view products.</p>
        </div> -->
      </b-container>
    </div>

    
    <b-modal
  id="shopify-connect-modal"
  title="Connect Your Store"
  ok-title="Connect"
  ok-variant="primary"
  @ok="handleShopifyConnect"
  centered
  header-bg-variant="light"
>
  <!-- Connect Shopify Modal -->
  <template #modal-header>
        <div class="w-100 modal-header-content">
          <h5 class="text-center modal-title">
            Connect BRIKS to Shopify
          </h5>
          <div class="d-flex align-items-center justify-content-center logo-container">
            <img 
              src="https://media.briks.ai/briks_logo.png" 
              alt="BRIKS" 
              class="brand-logo"
            />
            <feather-icon 
              icon="ArrowRightIcon" 
              size="24" 
              class="mx-3 text-secondary"
            />
            <img 
              src="https://www.svgrepo.com/show/303503/shopify-logo.svg" 
              alt="Shopify" 
              class="brand-logo"
            />
          </div>
        </div>
      </template>

      <!-- Modal Body - Adjusted for better mobile spacing -->
      <div class="modal-body-content">
        <b-form-group
          label="Enter your Shopify store URL"
          label-for="shopify-url"
          :state="shopUrlState"
          :invalid-feedback="shopUrlFeedback"
          class="custom-form-group"
        >
          <b-form-input
            id="shopify-url"
            v-model="shopUrl"
            placeholder="your-store.myshopify.com"
            :state="shopUrlState"
            class="custom-input"
            trim
          />
          <div class="info-text">
            <p class="example-text">
              <feather-icon 
                icon="InfoIcon" 
                size="14" 
                class="mr-2 text-primary"
              />
              Example: mystore.myshopify.com
            </p>
            <p class="verification-note">
              <feather-icon 
                icon="AlertCircleIcon" 
                size="14" 
                class="mr-2 text-warning"
              />
              Make sure you use same email address for both BRIKS and Shopify accounts for verification.
            </p>
          </div>
        </b-form-group>
      </div>

      <!-- Modal Footer - Mobile optimized -->
      <template #modal-footer="{ ok, cancel }">
        <div class="mobile-footer">
          <b-button
            variant="outline-secondary"
            @click="cancel()"
            class="footer-btn"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="ok()"
            :disabled="!shopUrl"
            class="footer-btn"
          >
            <feather-icon 
              icon="LinkIcon" 
              size="16" 
              class="mr-2"
            />
            Connect Store
          </b-button>
        </div>
      </template>
    </b-modal>

      <!-- Product Training Modal -->
      <product-training-modal 
      v-if="selectedProduct"
      :product="selectedProduct"
      @training-complete="handleTrainingComplete"
      @close="handleTrainingModalClose"
    />
    <create-product-modal
      ref="createProductModal"
      @submit="handleProductCreate"
      @hidden="handleCreateModalClose"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ProductTrainingModal from '@/views/Website/agents/ProductTrainingModal.vue';
import CreateProductModal from '@/views/Website/products/createProductModal.vue';
import { get } from 'lodash';
import QrUploadSection from '@/views/Website/products/QrUploadSection.vue';
import PhotoRequirements from '@/views/Website/products/photorequirements.vue' 
import axios from 'axios'

export default {
  name: 'productListing',

  metaInfo() {
    const pageTitle = 'Create & Process Product Images | BRIKS.AI';
    const pageDescription = 'Upload and transform your product photos into professional marketing visuals. Create stunning product images from simple phone photos using our AI technology.';
    const pageUrl = 'https://briks.ai/products';
    const pageImage = 'https://media.briks.ai/images/image-b07c74d2-f5bd-4136-b05b-2ef9fef23c34.png'; 

    return {
      title: pageTitle,
      titleTemplate: '%s | BRIKS.AI',
      meta: [
        // Primary meta tags
        { name: 'description', content: pageDescription },
        { name: 'keywords', content: 'product photography, AI image processing, photo transformation, product marketing, AI photography, BRIKS.AI' },
        
        // OpenGraph tags
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: pageUrl },
        { property: 'og:title', content: pageTitle },
        { property: 'og:description', content: pageDescription },
        { property: 'og:image', content: pageImage },
        
        // Twitter tags
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: pageUrl },
        { name: 'twitter:title', content: pageTitle },
        { name: 'twitter:description', content: pageDescription },
        { name: 'twitter:image', content: pageImage }
      ],
      link: [
        { rel: 'canonical', href: pageUrl }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "BRIKS.AI Product Image Creator",
            "applicationCategory": "Photography App",
            "description": pageDescription,
            "url": pageUrl,
            "image": pageImage,
            "offers": {
              "@type": "Offer",
              "category": "Professional Photography",
              "description": "Transform product photos into professional marketing visuals"
            },
            "featureList": [
              "AI-powered image transformation",
              "Professional photo backgrounds",
              "Custom scene creation",
              "Multiple style options",
              "Instant processing"
            ],
            "creator": {
              "@type": "Organization",
              "name": "BRIKS.AI",
              "url": "https://briks.ai"
            }
          }
        }
      ]
    };
  },
  components: {
    ProductTrainingModal,
    CreateProductModal,
    QrUploadSection,
    PhotoRequirements,
  },
  data() {
    return {
      isLoading: false,
      products: [],
      currentPage: 1,
      limit: 50,
      totalCount: 0,
      totalPages: 0,
      storeDetails: null,
      trainingStatuses: new Map(), // Store training status for each product
      currentTrainingId: null,
      isSyncing: false,
      shopUrl: '',
      shopUrlState: null,
      shopUrlFeedback: '',
      pollingInterval: null,
      selectedProduct: null,
      trainingProduct: null,
      trainingProgress: {
      isVisible: false,
      message: '',
      value: 0,
      maxValue: 100,
      completed: false
    },
    sseSource: null,
    };
  },
  computed: {
    ...mapState('app', ['userData']),
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
  },
  async created() {
  await this.initializeComponent();
},

async mounted() {
  await this.fetchProducts();
},

  methods: {
    async createNewProduct() {
    // Show create product modal
    this.$bvModal.show('modal-create-product');
  },
  viewAgent(agentId) {
        // Using Vue Router
        this.$router.push(`/agent/${agentId}`);
        
        // Or for a direct page navigation
        // window.location.href = `/agent/${agentId}`;
    },

  async handleProductCreate(productData) {
  try {
    this.isLoading = true;

    // Create FormData object
    const formData = new FormData();
    
    // Add product data
    const productPayload = {
      userId: this.userData.userId,
      shopId: this.storeDetails?.shopifyStoreId || 'manual',
      title: productData.title,
      description: productData.description || '',
      productType: productData.productType || 'product',
      tags: productData.tags || []
    };
    
    formData.append('productData', JSON.stringify(productPayload));
    
    // Add images
    productData.images.forEach(image => {
      formData.append('images', image.file); // Use the actual file object
    });

    // Create product API call
    const response = await fetch(`https://api.briks.ai/products/create`, {
      method: 'POST',
      body: formData,
      headers: {
        // Don't set Content-Type header - browser will set it with boundary for FormData
      }
    });

    const data = await response.json();

    if (data.success) {
      this.showToast('Images uploaded successfully', 'success');
      this.$bvModal.hide('modal-create-product'); // Hide the modal
      await this.fetchProducts(); // Refresh product list
    } else {
      throw new Error(data.error || 'Failed to create product');
    }

  } catch (error) {
    console.error('Error creating product:', error);
    this.showToast(error.message || 'Error creating product', 'error');
  } finally {
    this.isLoading = false;
  }
},

  // Update fetchProducts to include userId
  async fetchProducts() {
  if (!this.userData?.userId) return;
  
  this.isLoading = true;
  try {
    const response = await fetch(
      `https://api.briks.ai/products?userId=${this.userData.userId}&page=${this.currentPage}&limit=${this.limit}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    const data = await response.json();
    
    if (data.success) {
      // Map and combine both manual and Shopify products
      this.products = data.products.map(product => ({
        ...product,
        imageCount: product.images?.length || 0,
        source: product.shopId === 'manual' ? 'Manual' : 'Shopify'
      }));
      this.totalCount = data.totalCount;
      this.totalPages = data.totalPages;
    } else {
      throw new Error(data.error || 'Failed to fetch products');
    }
  } catch (error) {
    console.error('Error fetching products:', error);
    this.showToast('Error fetching products', 'error');
  } finally {
    this.isLoading = false;
  }
},
startPollingUserData() {
  let attempts = 0;
  const maxAttempts = 60; // 5 minutes with 5-second intervals

  this.pollingInterval = setInterval(async () => {
    try {
      attempts++;
      
      // Get latest user data including store details
      const updatedUserData = await this.$store.dispatch('app/fetchUserData');
      
      if (updatedUserData?.storeDetails?.length) {
        const storeDetail = updatedUserData.storeDetails[0];
        this.storeDetails = storeDetail;

        // Check sync status
        const syncStatus = await this.checkSyncStatus(storeDetail.shopifyStoreDomain);
        
        if (syncStatus.success) {
          // Clear polling and update UI
          this.clearPolling();
          await this.fetchProducts();
          this.showToast('Shopify store connected and synced successfully!', 'success');
        } else if (attempts >= maxAttempts) {
          // Stop polling after max attempts
          this.clearPolling();
          this.showToast('Store connected but sync is taking longer than expected. Please try manual sync.', 'warning');
        }
      } else if (attempts >= maxAttempts) {
        this.clearPolling();
        this.showToast('Could not complete store connection. Please try again.', 'error');
      }

    } catch (error) {
      console.error('Polling error:', error);
      this.clearPolling();
      this.showToast('Error connecting to store', 'error');
    }
  }, 5000); // Poll every 5 seconds
},
clearPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  },
  async checkSyncStatus(shopDomain) {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/api/sync-status?` + 
      `shop=${shopDomain}&` +
      `userId=${this.userData.userId}`
    );
    return await response.json();
  } catch (error) {
    console.error('Error checking sync status:', error);
    return { success: false };
  }
},


    connectShopify() {
    this.shopUrl = ''; // Reset input
    this.shopUrlState = null;
    this.shopUrlFeedback = '';
    this.$bvModal.show('shopify-connect-modal');
  },

  validateShopUrl(url) {
    if (!url) {
      this.shopUrlFeedback = 'Store URL is required';
      return false;
    }
    if (!url.includes('myshopify.com')) {
      this.shopUrlFeedback = 'Please enter a valid Shopify store URL';
      return false;
    }
    return true;
  },
  handleCreateModalClose() {
  this.$refs.createProductModal.$emit('reset'); // Reset the form
},

async handleShopifyConnect(bvModalEvent) {
  try {
    bvModalEvent.preventDefault();

    this.shopUrlState = this.validateShopUrl(this.shopUrl);
    if (!this.shopUrlState) return;

    const cleanShop = this.shopUrl
      .replace('https://', '')
      .replace('http://', '')
      .replace(/\/$/, '');

    this.$bvModal.hide('shopify-connect-modal');

    // Pass userId when opening the connection window
    const connectUrl = `${process.env.VUE_APP_API_URL}?` + 
      `shop=${cleanShop}&` +
      `userId=${this.userData.userId}`;

    window.open(connectUrl, '_blank');

    this.showToast('Connecting to Shopify...', 'info');
    this.startPollingUserData();

  } catch (error) {
    console.error('Error connecting to Shopify:', error);
    this.showToast('Error connecting to Shopify store', 'error');
  }
},

  async initializeComponent() {
  try {
    // Fetch fresh user data
    await this.$store.dispatch('app/fetchUserData');
    const userData = this.$store.state.app.userData;
    
    this.storeDetails = userData?.storeDetails?.[0];
    
    await this.fetchProducts();
  } catch (error) {
    console.error('Error initializing component:', error);
    this.showToast('Error loading products', 'error');
  }
},

    async fetchShopifyProducts() {
      if (!this.storeDetails?.shopifyStoreId) return;
      
      this.isLoading = true;
      try {
        const response = await fetch(
          `https://store.briks.ai/api/stores/${this.storeDetails.shopifyStoreId}/products?page=${this.currentPage}&limit=${this.limit}`
        );
        const data = await response.json();
        
        if (data.success) {
          this.products = data.products;
          this.totalCount = data.totalCount;
          this.totalPages = data.totalPages;
          
          // Check training status for each product
          // this.products.forEach(product => {
          //   this.checkTrainingStatus(product.id);
          // });
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        this.showToast('Error fetching products', 'error');
      } finally {
        this.isLoading = false;
      }
    },

    async syncProducts() {
  if (!this.storeDetails?.shopifyStoreDomain) {
    this.showToast('No store connected', 'error');
    return;
  }

  this.isSyncing = true;
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/api/sync-products`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          shop: this.storeDetails.shopifyStoreDomain,
          userId: this.userData.userId
        })
      }
    );
    
    const data = await response.json();
    
    if (data.success) {
      this.showToast('Products synced successfully');
      await this.fetchProducts();
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    console.error('Sync error:', error);
    this.showToast(error.message || 'Error syncing products', 'error');
  } finally {
    this.isSyncing = false;
  }
},

async startTraining(product) {
  try {
    // First check usage limits
    const usageResponse = await axios.get(`${process.env.VUE_APP_BILLING_URL}usage/check`, {
      params: {
        userId: this.$store.state.app.userData?.userId,
        operationType: 'imageProcessing'
      }
    });

    const { canProceed, usageDetails, message } = usageResponse.data;

    if (!canProceed) {
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: 'AlertTriangleIcon',
          title: 'Usage Limit Reached',
          text: message || 'You have reached your image processing limit.',
          variant: 'warning',
          action: {
            label: 'Upgrade Now',
            onClick: () => this.$router.push('/plans'),
            buttonVariant: 'success'
          }
        },
        timeout: 5000
      });
      return;
    }

    // Check existing training after usage check
    const existingTrainingId = localStorage.getItem('trainingId');
    
    if (existingTrainingId) {
      const confirmResult = await this.$bvModal.msgBoxOk(
        'A training session is already in progress. Please wait for it to complete.', {
        title: 'Training in Progress',
        okVariant: 'primary',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
        okTitle: 'View Progress'
      });

      if (confirmResult) {
        this.selectedProduct = {...product};
        this.$nextTick(() => {
          this.$bvModal.show('modal-product-training');
        });
      }
      return;
    }

    // If usage is within limits and no existing training, proceed with new training
    this.selectedProduct = {...product};
    this.$nextTick(() => {
      this.$bvModal.show('modal-product-training');
    });

    // Show remaining usage in a toast
    // this.$toast({
    //   component: ToastificationContent,
    //   props: {
    //     icon: 'InfoIcon',
    //     title: 'Usage Status',
    //     text: `${usageDetails.currentUsage}/${usageDetails.limit} image processes used this billing period`,
    //     variant: 'info'
    //   },
    //   timeout: 3000
    // });

  } catch (error) {
    console.error('Error starting training:', error);
    this.$toast({
      component: ToastificationContent,
      props: {
        icon: 'XIcon',
        title: 'Error',
        text: 'Unable to start training session. Please try again.',
        variant: 'danger'
      },
      timeout: 5000
    });
  }
},

  handleTrainingComplete(agentId) {
    try {
      // Update product status in the list
      const productIndex = this.products.findIndex(p => p.id === this.selectedProduct?.id);
      if (productIndex !== -1) {
        this.$set(this.products[productIndex], 'trainingStatus', true);
        this.$set(this.products[productIndex], 'agentId', agentId);
      }

      // Clear training states
      this.currentTrainingId = null;
      localStorage.removeItem('trainingId');
      
      // Hide modal
      this.$bvModal.hide('modal-product-training');
      
      // Clear selected product
      this.selectedProduct = null;
      
      // Show success message
      this.showToast('Training completed successfully!', 'success');
      
      // Refresh product list
      this.fetchProducts();
    } catch (error) {
      console.error('Error handling training completion:', error);
      this.showToast('Error updating training status', 'error');
    }
  },

  handleTrainingModalClose() {
    const existingTrainingId = localStorage.getItem('trainingId');
    if (!existingTrainingId) {
      this.selectedProduct = null;
    }
  },

    // Clean up on component destroy
beforeDestroy() {
  this.clearPolling();
},

    pollTrainingStatus(productId) {
      const pollInterval = setInterval(async () => {
        const status = await this.checkTrainingStatus(productId);
        if (status === 'completed' || status === 'failed') {
          clearInterval(pollInterval);
        }
      }, 30000); // Poll every 30 seconds
    },

    getTrainingStatus(productId) {
      return this.trainingStatuses.get(productId) === 'completed';
    },

    isTraining(productId) {
      return this.trainingStatuses.get(productId) === 'training';
    },

    formatDate(dateString) {
      return new Date(dateString).toLocaleString();
    },

    showToast(message, variant = 'success') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: variant === 'success' ? 'CheckIcon' : 'AlertTriangleIcon',
          variant
        },
      });
    },
  }
};
</script>

<style scoped>
/* Agent card styles */
.agent-card {
  margin-bottom: 1rem;
  position: relative;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.agent-list-img-object-fit {
  height: 300px;
  width: 100%;
  object-fit: contain;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.instruction-list {
  font-size: 1.2rem; /* Increases font size */
  color: #333; /* Text color for better readability */
  padding-left: 0rem; /* Add space for tick mark */
  list-style: none; /* Remove default bullet points */
  padding-bottom: 0.2rem;
  padding-top: 0rem;
}

.instruction-list li {
  position: relative; 
  margin-bottom: 1rem;
  
}

.instruction-list li::before {
  content: '✔'; /* Unicode for a tick mark */
  color: green; /* Color for the tick mark */
  /* position: absolute;  */
  left: 0; /* Align tick mark to the left */
  font-size: 1.2rem; /* Match the font size */
  padding: 0.5rem;
  
}


.agent-name {
  font-weight: normal;
  font-size: 1.25rem;
  padding-left: 13px;
  padding: 1rem .25rem;
}


@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.fa-spin {
  animation: spin 1s linear infinite;
}

.agent-headline {
  font-size: 0rem;
  color: rgb(118, 116, 116) !important;
  margin-top: 5px !important;
  margin-bottom: -15px;
}

:v-deep .modal-content {
  border-radius: 12px;
  border: none;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}




.modal-body-content {
  padding: 1rem 2rem 0rem;
}

.custom-form-group label {
  color: #2c3e50;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.info-text {
  padding: 0rem 0;
}

.example-text {
  color: #4a5568;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.verification-note {
  color: #2d3748;
  font-size: 0.9rem;
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
  background: #fffbeb;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 0.75rem;
  border: 1px solid #fef3c7;
}

::v-deep .modal-footer {
  padding: 1.5rem 2rem;
  border-top: 1px solid #f0f0f0;
}

::v-deep .modal-content {
  border-radius: 12px;
  border: none;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}


/* Form styles */

.custom-form-group label {
  color: #2c3e50;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.custom-input {
  border: 1.5px solid #e2e8f0;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.custom-input:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}


/* Info text styles */
.info-text {
  padding: 0.5rem 0;
}

.example-text {
  color: #4a5568;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.verification-note {
  color: #2d3748;
  font-size: 0.9rem;
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
  background: #fffbeb;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 0.75rem;
  border: 1px solid #fef3c7;
}

/* Footer styles */
::v-deep .modal-footer {
  padding: 1.5rem 2rem;
  border-top: 1px solid #f0f0f0;
}

.btn {
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  border-radius: 6px;
}

.btn-primary {
  background-color: #4a90e2;
  border-color: #4a90e2;
}

.btn-primary:hover:not(:disabled) {
  background-color: #357abd;
  border-color: #357abd;
}

.btn-outline-secondary {
  color: #64748b;
  border-color: #e2e8f0;
}

.btn-outline-secondary:hover {
  background-color: #f8fafc;
  color: #475569;
  border-color: #cbd5e1;
}

.top-right-icons {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.top-right-icon {
  margin-bottom: 5px;
  cursor: pointer;
}

.b-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  
  border-radius: 0 !important;
}

.b-card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0 0 0 !important; /* Remove horizontal padding */
  
}

/* Button styling */
.cart-btn {
  width: 100%;
  margin: 0;
  padding-top: 0px;
  border-radius: 0 !important;

}

.upload-btn {
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 8px;
  margin-left:0rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.upload-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* Add pulse animation for extra attention */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(0,123,255,0.4); }
  70% { box-shadow: 0 0 0 10px rgba(0,123,255,0); }
  100% { box-shadow: 0 0 0 0 rgba(0,123,255,0); }
}

.upload-btn:not(:hover) {
  animation: pulse 2s infinite;
}

.no-border-radius {
  border-radius: 0 !important;
}

.love-icon, .share-icon {
  width: auto;
  height: auto;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 55%;
}

.filled-heart {
  color: red !important;
}

.empty-heart {
  color: rgb(0, 0, 0) !important;
}

.agent-rating {
  padding-right: 1rem; 
  padding-left: 1rem;
}
.button-container {
  margin: 0rem;
}

.action-btn {
  border-radius: 0 !important;
  margin: 0 !important;
  width: 100%;
  padding: 10px;
}

.action-btn.completed-training {
  background-color: primary !important;
  border-color: primary!important;
  color: white !important;
}


/* Remove any other button margins */
.cart-btn {
  margin: 0 !important;
  border-radius: 0 !important;
}

/* Additional style to ensure card has no overflow */
.agent-card {
  overflow: hidden;
  border-radius: 0 !important;
}

.agent-stats {
  font-size: 0.875rem;
}

.feather-icon {
  vertical-align: middle;
}

/* Carousel customization */
.carousel-indicators {
  margin-bottom: 0;
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-indicators li {
  background-color: rgba(255, 255, 255, 0.7);
}

.carousel-indicators .active {
  background-color: #fff;
}


::v-deep .modal-header {
  border-bottom: none;
  flex-direction: column;
}

::v-deep .modal-header .close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

/* Mobile specific styles */
@media (max-width: 576px) {
  ::v-deep .modal-content {
    margin: 0.5rem;
  }

  .modal-title {
    font-size: 1.25rem;
  }

  .brand-logo {
    height: 30px;
  }

  .modal-body-content {
    padding: 0.5rem 1rem;
  }

  .custom-input {
    font-size: 0.95rem;
  }

  .verification-note {
    font-size: 0.85rem;
    padding: 0.75rem;
  }

  .upload-btn {
  padding: 12px 24px;
  margin-left:0rem;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.upload-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* Add pulse animation for extra attention */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(0,123,255,0.4); }
  70% { box-shadow: 0 0 0 10px rgba(0,123,255,0); }
  100% { box-shadow: 0 0 0 0 rgba(0,123,255,0); }
}

.upload-btn:not(:hover) {
  animation: pulse 2s infinite;
}



@media (max-width: 576px) {
  
  .footer-btn {
  flex: 1;
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
}
}

/* Larger screens */
@media (min-width: 577px) {
  ::v-deep .modal-content {
    max-width: 500px;
    margin: 1rem auto;
  }
}
</style>

<style scoped>
/* Base modal styles */
::v-deep .modal-content {
  border-radius: 12px;
  border: none;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  margin: 0.5rem;
}

/* Header styles */
.modal-header-content {
  padding: 1.5rem 1rem 0.5rem;
}

.modal-title {
  color: #000000;
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.logo-container {
  margin-bottom: 1rem;
}

.brand-logo {
  height: 35px;
  width: auto;
}

/* Body styles */
.modal-body-content {
  padding: 0.5rem 1.5rem;
}

.custom-form-group label {
  color: #2c3e50;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.custom-input {
  border: 1.5px solid #e2e8f0;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  transition: all 0.2s ease;
}

/* Info text styles */
.info-text {
  padding: 0.5rem 0;
  margin-top: 1rem;
}

.example-text {
  color: #4a5568;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.verification-note {
  color: #2d3748;
  font-size: 0.9rem;
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
  background: #fffbeb;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #fef3c7;
}

/* Footer styles */
::v-deep .modal-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid #f0f0f0;
}

.mobile-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.footer-btn {
  flex: 1;
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile specific styles */
@media (max-width: 576px) {
  ::v-deep .modal-content {
    margin: 0.5rem;
  }

  .modal-title {
    font-size: 1.25rem;
  }

  .brand-logo {
    height: 30px;
  }

  .modal-body-content {
    padding: 0.5rem 1rem;
  }

  .custom-input {
    font-size: 0.95rem;
  }

  .verification-note {
    font-size: 0.85rem;
    padding: 0.75rem;
  }

  .footer-btn {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
  }
}

/* Larger screens */
@media (min-width: 577px) {
  ::v-deep .modal-content {
    max-width: 500px;
    margin: 1rem auto;
  }
}
</style>